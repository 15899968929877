import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { TextField } from "@material-ui/core";
import Logout from "components/Logout";
import EmprestimoCard from "./EmprestimoCard";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { useLocation } from "react-router-dom";
const { API_URL } = require("../../consts");
const { cpfMask, getAutenticado } = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  campoSenha: {
    marginRight: "5px",
  },
};

const useStyles = makeStyles(styles);

export default function ContasReceber() {
  const path = useLocation();
  const [dados, setDados] = useState([]);
  let [logado, setLogado] = useState(true);
  const classes = useStyles();

  useEffect(async () => {
    if (path && path.pathname && path.pathname.startsWith("/admin/contas/")) {
      const chave = path.pathname.replace("/admin/contas/", "");
      setChavePesquisa(chave);
      buscaContas(chave);
    }
  }, []);

  //snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const [chavePesquisa, setChavePesquisa] = useState("");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const mostraErro = (mensagem) => {
    setCor("danger");
    setMensagemErro(mensagem);
    showNotification();
  };

  const mostraInfo = (mensagem) => {
    setCor("info");
    setMensagemErro(mensagem);
    showNotification();
  };

  const buscaContas = async (cpf) => {
    try {
      const result = await getAutenticado(API_URL + `contascliente/${cpf}`);
      if (result.data) {
        if (result.data.sucesso) {
          if (result.data.data.length === 0) {
            mostraInfo("Cliente não tem propostas");
          }
          setDados(result.data.data);
        } else {
          mostraErro(result.data.mensagem);
        }
      }
    } catch (error) {
      mostraErro(error.message);
      if (error.response && error.response.status == 401) setLogado(false);
    }
  };

  return logado ? (
    <React.Fragment>
      <Card>
        <CardHeader color="primary">
          <h5 className={classes.cardTitleWhite}>Parcelas do Cliente</h5>
        </CardHeader>
        <CardBody>
          <TextField
            label="CPF"
            name="cpf"
            value={chavePesquisa}
            onChange={(e) => setChavePesquisa(cpfMask(e.target.value))}
          />
          <Button color="primary" onClick={() => buscaContas(chavePesquisa)}>
            Buscar
          </Button>
        </CardBody>
      </Card>
      <GridContainer>
        {dados.map((empr) => (
          <GridItem key={empr.ID}>
            <EmprestimoCard emprestimo={empr} />
          </GridItem>
        ))}
      </GridContainer>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </React.Fragment>
  ) : (
    <Logout />
  );
}
