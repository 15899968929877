import { React, useState } from "react";
import PropTypes from "prop-types";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import { makeStyles } from "@material-ui/core/styles";
const {
  formataData,
  mascaraPhone,
  cpfMask,
  formataValorReais,
  atualizaValor,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
};

const styleQuitado = {
  color: "green",
};
const styleVencido = {
  color: "red",
};
const styleRenegociado = {
  color: "blue",
  fontWeight: "bold",
};
const styleProtestado = {
  color: "purple",
  fontWeight: "bold",
};
const styleCobrancaExterna = {
  color: "yellow",
  backgroundColor: "gray",
  fontWeight: "bold",
};
const useStyles = makeStyles(styles);
const EmprestimoCard = (props) => {
  const [mostraAtual, setMostraAtual] = useState(false);
  const tableHeaders = [
    "Parcela",
    "Vencimento",
    "Valor",
    mostraAtual ? "Vlr. Atualizado" : "Vlr. Em Aberto",
  ];
  const classes = useStyles();
  const dataAtual = new Date();
  const { emprestimo } = props;
  //#parcela  vencimento - valor - valor em aberto
  const tableData = emprestimo.rows.map((parc) => {
    const style = parc.DATA_COBRANCA_EXTERNA
      ? styleCobrancaExterna
      : parc.DATA_PROTESTO
      ? styleProtestado
      : parc.STATUS_PARCELA === "R"
      ? styleRenegociado
      : parc.VLR_SALDO == 0.0
      ? styleQuitado
      : dataAtual.toISOString().substring(0, 10) > parc.VENCIMENTO &&
        parc.VLR_SALDO > 0.0
      ? styleVencido
      : null;

    return [
      <span key={`par${parc.NUMERO}`} style={style}>
        {parc.NUMERO}
        {parc.STATUS_PARCELA === "R" ? "R" : parc.DATA_PROTESTO ? "P" : null}
      </span>,
      <span key={`ven${parc.NUMERO}`} style={style}>
        {formataData(parc.VENCIMENTO)}
      </span>,
      <span key={`vlr${parc.NUMERO}`} style={style}>
        {formataValorReais(parc.VLR_PARCELA)}
      </span>,
      <span key={`sal${parc.NUMERO}`} style={style}>
        {mostraAtual
          ? !parc.DATA_COBRANCA_EXTERNA &&
            !parc.DATA_PROTESTO &&
            parc.STATUS_PARCELA !== "R"
            ? formataValorReais(atualizaValor(parc.VLR_SALDO, parc.VENCIMENTO))
            : ""
          : formataValorReais(parc.VLR_SALDO)}
      </span>,
    ];
  });
  return (
    <Card key={emprestimo.ID}>
      <CardHeader
        color={emprestimo.TIPO_NEGOCIACAO === "E" ? "primary" : "info"}
      >
        <p className={classes.cardTitleWhite}>Proposta: #{emprestimo.ID}</p>
        <p>Cliente: {emprestimo.NOME_CLIENTE}</p>
        <p>Telefone: {mascaraPhone(emprestimo.TELEFONE)}</p>
        <p>CPF: {cpfMask(emprestimo.CPF)}</p>
        <p>Valor Venda: {emprestimo.VALOR_VENDA}</p>
        <p>Valor Total Parcelas: {emprestimo.VALOR_PARCELAS}</p>
        <p>Obs.: {emprestimo.OBSERVACAO}</p>
        <p>Loja.: {emprestimo.NOME_ESTAB}</p>
      </CardHeader>
      <CardBody>
        <div
          onClick={() => setMostraAtual(!mostraAtual)}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          Valores: {mostraAtual ? "atualizados" : "nominais"}
        </div>
        <Table
          tableHeaderColor="primary"
          tableHead={tableHeaders}
          tableData={tableData}
        />
      </CardBody>
    </Card>
  );
};

EmprestimoCard.propTypes = {
  emprestimo: PropTypes.shape({
    ID: PropTypes.number.isRequired,
    NOME_CLIENTE: PropTypes.string,
    TELEFONE: PropTypes.string,
    CPF: PropTypes.string,
    OBSERVACAO: PropTypes.string,
    NOME_ESTAB: PropTypes.string,
    VALOR_VENDA: PropTypes.string.isRequired,
    VALOR_PARCELAS: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    ID_CLIENTE: PropTypes.number.isRequired,
    TIPO_NEGOCIACAO: PropTypes.string,
  }),
};

export default EmprestimoCard;
