import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Logout from "components/Logout";
const { getAutenticado } = require("../../js_utils/util");
const { formatSQLDate, formataValorReais } = require("../../js_utils/util");
const { API_URL } = require("../../consts");

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function MeusRepasses() {
  const classes = useStyles();
  const [logado, setLogado] = useState(true);
  const [repassesData, setRepassesData] = useState([[]]);
  const buscaRepasses = async () => {
    try {
      const results = await getAutenticado(API_URL + "meusrepasses");
      let items = [];
      results.data.forEach((item) => {
        items.push([
          item.PROPOSTA,
          item.NOME,
          formataValorReais(item.VLR_A_REPASSAR),
          formataValorReais(item.VLR_SALDO_A_REPASSAR),
          formatSQLDate(item.DT_PREVISTA_REPASSE),
        ]);
      });
      setRepassesData(items);
    } catch (err) {
      console.log(err.message);
      setLogado(false);
    }
  };
  useEffect(buscaRepasses, []);
  return logado ? (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Meus Repasses</h4>
      </CardHeader>
      <CardBody>
        <Table
          tableHeaderColor="primary"
          tableHead={[
            "Proposta",
            "Cliente",
            "Valor do Repasse",
            "Saldo a Receber",
            "Previsão Repasse",
          ]}
          tableData={repassesData}
        ></Table>
      </CardBody>
    </Card>
  ) : (
    <Logout></Logout>
  );
}
