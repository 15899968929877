import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cpfMask, validaCpf, mascaraCEP, mascaraPhone } from "js_utils/util.js";
// core components
import Logout from "components/Logout";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import axios from "axios";
import logout from "js_utils/logout";

const { getAutenticado, postAutenticado } = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const { cidadesCompleto, estadosCompleto } = require("../../variables/geo");

export default function Clientes() {
  const { API_URL } = require("../../consts");
  let [logado, setLogado] = useState(true);
  const [cidadesEstado, setCidadesEstado] = useState(cidadesCompleto.data[31]);
  const estados = estadosCompleto.data;
  let [selecCidade, setSelecCidade] = useState(false);
  // prettier-ignore
  const [cidadeSelecionada, setCidadeSelecionada] = useState(
    cidadesCompleto.data[31][0],
  );
  const [estadoSelecionado, setEstado] = useState(estados[31]);
  const [clientes, setClientes] = useState([[]]);
  const [cadastraDisabled, setCadastraDisabled] = useState(false);
  const limpaDados = () => {
    setNome("");
    setFone("");
    setEndereco("");
    setNumero("");
    setBairro("");
    setCep("");
    setRg("");
    setProfissao("");
    setEstadoCivil("");
    setComplemento("");
  };

  const pesquisaCliente = async () => {
    const valido = validaCpf(cpf);
    if (!valido) {
      setMensagemErro("CPF inválido");
      setCorSnack("danger");
      showNotification();
      setCpf("");
    } else {
      const token = localStorage.getItem("vsToken");
      try {
        const resultado = await axios.get(API_URL + "cliente/" + cpf, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!resultado.data.success) setEtapa(2);
        else {
          setMensagemErro("CPF já cadastrado. Atualizando lista...");
          setCorSnack("success");
          showNotification();
          try {
            await postAutenticado(API_URL + "associacliente/" + cpf, {});
            carregaClientes();
          } catch (err) {
            console.log(JSON.stringify(err.message));
          }
        }
      } catch (error) {
        //console.log(JSON.stringify(error.response.data));
        if (error.response && error.response.data.erro_auth)
          console.log("Sem token");
        logout();
      }
    }
  };
  const carregaClientes = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "getClientes");
      const clis = resultados.data.data.map((item) => {
        const cliente = {
          CPF: item.CPF,
          NOME: item.NOME,
          LOGRADOURO: item.LOGRADOURO,
          NUMERO: item.NUMERO,
          FONE: item.TELEFONE,
          COMPLEMENTO: item.COMPLEMENTO,
          ESTADO_CIVIL: item.ESTADO_CIVIL,
          RG: item.RG,
          PROFISSAO: item.PROFISSAO,
          CEP: item.CEP,
          BAIRRO: item.BAIRRO,
          COD_ESTADO: item.COD_ESTADO,
          COD_CIDADE: item.COD_CIDADE,
        };
        return [
          <Link key={item.CPF} to={`/admin/contas/${item.CPF}`}>
            {cpfMask(item.CPF)}
          </Link>,
          item.NOME,
          item.LOGRADOURO + ", num° " + item.NUMERO,
          item.CIDADE + "/" + item.SIGLA,
          <Button
            key={item.CPF}
            color="info"
            onClick={
              // prettier-ignore
              () => {
              let estado = estados.find((item) => {
                return item.CODIGO_IBGE === cliente.COD_ESTADO;
              });
              let cidade = cidadesCompleto.data[cliente.COD_ESTADO].find(
                (item) => {
                  return item.CODIGO_IBGE === cliente.COD_CIDADE;
                },
              );
              setClienteEdicao(cliente);
              setEstadoEdicao(estado);
              setCidadeSelecionadaEdicao(cidade);
              setEtapa(3);
            }
            }
          >
            Editar
          </Button>,
        ];
      });
      setClientes(clis);
    } catch (err) {
      console.log(err.message);
      setClientes([[]]);
      if (err.response && err.response.status == 401) setLogado(false);
    }
  };
  useEffect(carregaClientes, []);

  const estadoChange = (value) => {
    if (value && value.CODIGO_IBGE) {
      setCidadesEstado(cidadesCompleto.data[value.CODIGO_IBGE]);
      setCidadeSelecionada(cidadesCompleto.data[value.CODIGO_IBGE][0]);
      const selec = estados.find((item) => {
        return item.CODIGO_IBGE === value.CODIGO_IBGE;
      });
      setEstado(selec);

      setSelecCidade(true);
    } else {
      setEstado(estados[31]);
      setCidadesEstado(cidadesCompleto.data[31]);
      setCidadeSelecionada(cidadesCompleto.data[31][0]);
      setSelecCidade(false);
    }
  };

  // prettier-ignore
  const [cidadesEstadoEdicao, setCidadesEstadoEdicao] = useState(
    cidadesCompleto.data[31],
  );
  // prettier-ignore
  const [cidadeSelecionadaEdicao, setCidadeSelecionadaEdicao] = useState(
    cidadesCompleto.data[31][0],
  );
  const [estadoSelecionadoEdicao, setEstadoEdicao] = useState(estados[31]);
  //let [selecCidadeEdicao, setSelecCidadeEdicao] = useState(false);

  const cidadeChangeEdicao = (value) => {
    if (value) setCidadeSelecionadaEdicao(value);
    clienteEdicao.COD_CIDADE = value.CODIGO_IBGE;
  };

  const estadoEdicaoChange = (value) => {
    if (value && value.CODIGO_IBGE) {
      setCidadesEstadoEdicao(cidadesCompleto.data[value.CODIGO_IBGE]);
      setCidadeSelecionadaEdicao(cidadesCompleto.data[value.CODIGO_IBGE][0]);
      clienteEdicao.COD_CIDADE =
        cidadesCompleto.data[value.CODIGO_IBGE][0].CODIGO_IBGE;
      const selec = estados.find((item) => {
        return item.CODIGO_IBGE === value.CODIGO_IBGE;
      });
      setEstadoEdicao(selec);
      clienteEdicao.COD_ESTADO = value.CODIGO_IBGE;
      //setSelecCidadeEdicao(true);
    } else {
      setEstadoEdicao(estados[31]);
      setCidadesEstadoEdicao(cidadesCompleto.data[31]);
      setCidadeSelecionadaEdicao(cidadesCompleto.data[31][0]);
      //setSelecCidadeEdicao(false);
    }
  };

  const clienteEdicaoChange = (e) => {
    let { name, value } = e.target;
    if (name === "FONE") value = mascaraPhone(value);
    if (name === "CEP") value = mascaraCEP(value);
    setClienteEdicao((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const cidadeChange = (value) => {
    if (value) setCidadeSelecionada(value);
  };

  const [alerta, setAlerta] = useState(false);
  const [cpf, setCpf] = useState("");
  const [cep, setCep] = useState("");
  const [fone, setFone] = useState("");
  const [profissao, setProfissao] = useState("");
  const [rg, setRg] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [endereco, setEndereco] = useState("");
  const [nome, setNome] = useState("");
  const [bairro, setBairro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");

  const [clienteEdicao, setClienteEdicao] = useState(null);

  const mudaCPF = (e) => {
    setCpf(cpfMask(e.target.value));
  };

  const mudaCEP = (e) => {
    setCep(mascaraCEP(e.target.value));
  };

  const mudaFone = (e) => {
    setFone(mascaraPhone(e.target.value));
  };
  let [etapa, setEtapa] = useState(1);
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  let [mensagemErro, setMensagemErro] = useState("");
  let [corSnack, setCorSnack] = useState("warning");
  const confereDados = async () => {
    let erro = false;
    setMensagemErro("");
    console.log(fone, fone.length);
    if (!nome || nome.length < 3) {
      erro = true;
      setMensagemErro("Confira o Nome!\n");
    } else if (!fone || fone.replace(/\D/g, "").length < 10) {
      erro = true;
      setMensagemErro("Confira o Telefone!\n");
    } else if (!profissao || profissao.length < 3) {
      erro = true;
      setMensagemErro("Confira a Profissão!\n");
    } else if (!rg || rg.length < 6) {
      erro = true;
      setMensagemErro("Confira o RG!\n");
    } else if (!estadoCivil || estadoCivil.length < 3) {
      erro = true;
      setMensagemErro("Confira o Estado Civil!\n");
    } else if (!endereco || endereco.length < 3) {
      erro = true;
      setMensagemErro("Confira o Logradouro!\n");
    } else if (!numero || numero.length < 1) {
      erro = true;
      setMensagemErro("Confira o Número!");
    } else if (!bairro || bairro.length < 1) {
      erro = true;
      setMensagemErro("Confira o Bairro!\n");
    } else if (!cep || cep.length < 9) {
      erro = true;
      setMensagemErro("Confira o Cep!");
    } else if (!estadoSelecionado) {
      erro = true;
      setMensagemErro("Confira a UF!");
    } else if (!cidadeSelecionada) {
      erro = true;
      setMensagemErro("Confira a cidade!");
    }
    if (erro) {
      setCorSnack("danger");
      showNotification();
    } else {
      setCadastraDisabled(true);
      try {
        await postAutenticado(API_URL + "cadastracliente", {
          CPF: cpf,
          NOME: nome,
          LOGRADOURO: endereco,
          BAIRRO: bairro,
          NUMERO: numero,
          TELEFONE: fone,
          ESTADO_CIVIL: estadoCivil,
          PROFISSAO: profissao,
          RG: rg,
          CIDADE: cidadeSelecionada.CODIGO_IBGE,
          COMPLEMENTO: complemento ? complemento : "",
          CEP: cep,
        });
        carregaClientes();
        setEtapa(1);
        limpaDados();
        setMensagemErro("Cliente Cadastrado com sucesso");
        setCorSnack("success");
        showNotification();
      } catch (err) {
        setMensagemErro("Erro no servidor. Tente mais tarde");
        setCorSnack("danger");
        showNotification();
      } finally {
        setCadastraDisabled(false);
      }
    }
  };

  const confereEdicao = async () => {
    let erro = false;
    setMensagemErro("");
    if (!clienteEdicao.NOME || clienteEdicao.NOME.length < 3) {
      erro = true;
      setMensagemErro("Confira o Nome!\n");
    } else if (
      !clienteEdicao.FONE ||
      clienteEdicao.FONE.replace(/\D/g, "").length < 10
    ) {
      erro = true;
      setMensagemErro("Confira o Telefone!\n");
    } else if (
      !clienteEdicao.LOGRADOURO ||
      clienteEdicao.LOGRADOURO.length < 3
    ) {
      erro = true;
      setMensagemErro("Confira o Logradouro!\n");
    } else if (!clienteEdicao.NUMERO || clienteEdicao.NUMERO.length < 1) {
      erro = true;
      setMensagemErro("Confira o Número!");
    } else if (!clienteEdicao.BAIRRO || clienteEdicao.BAIRRO.length < 1) {
      erro = true;
      setMensagemErro("Confira o Bairro!\n");
    } else if (!clienteEdicao.CEP || clienteEdicao.CEP.length < 8) {
      erro = true;
      setMensagemErro("Confira o Cep!");
    } else if (!estadoSelecionadoEdicao) {
      erro = true;
      setMensagemErro("Confira a UF!");
    } else if (!cidadeSelecionadaEdicao) {
      erro = true;
      setMensagemErro("Confira a cidade!");
    }
    if (erro) {
      setCorSnack("danger");
      showNotification();
    } else {
      setCadastraDisabled(true);
      try {
        await postAutenticado(API_URL + "alteracliente", {
          dados: {
            CPF: clienteEdicao.CPF,
            NOME: clienteEdicao.NOME,
            LOGRADOURO: clienteEdicao.LOGRADOURO,
            BAIRRO: clienteEdicao.BAIRRO,
            NUMERO: clienteEdicao.NUMERO,
            RG: clienteEdicao.RG,
            ESTADO_CIVIL: clienteEdicao.ESTADO_CIVIL,
            PROFISSAO: clienteEdicao.PROFISSAO,
            TELEFONE: clienteEdicao.FONE,
            COD_CIDADE: cidadeSelecionadaEdicao.CODIGO_IBGE,
            COMPLEMENTO: clienteEdicao.COMPLEMENTO
              ? clienteEdicao.COMPLEMENTO
              : "",
            CEP: clienteEdicao.CEP,
          },
        });
        carregaClientes();
        setEtapa(1);
        limpaDados();
        setMensagemErro("Cliente alterado com sucesso");
        setCorSnack("success");
        showNotification();
      } catch (err) {
        if (err.response.data.mensagem)
          setMensagemErro(err.response.data.mensagem);
        else setMensagemErro("Erro no processamento. Tente mais tarde");
        setCorSnack("danger");
        showNotification();
      } finally {
        setCadastraDisabled(false);
      }
    }
  };

  const classes = useStyles();
  return logado ? (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          {etapa == 1 ? (
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Cadastrar Cliente</h4>
                <p className={classes.cardCategoryWhite}>
                  Preencha o CPF e clique em Pesquisar
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      label="CPF"
                      id="cpf-pesquisa"
                      onChange={mudaCPF}
                      value={cpf}
                      fullWidth={true}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={pesquisaCliente}>
                  Pesquisar
                </Button>
              </CardFooter>
            </Card>
          ) : null}
          {etapa == 2 ? (
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Cadastrar Cliente</h4>
                <p className={classes.cardCategoryWhite}>
                  Preencha os dados do cliente {cpf}
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <TextField
                      label="Nome"
                      id="nome-cadastro"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      label="RG"
                      id="rg-cadastro"
                      value={rg}
                      onChange={(e) => setRg(e.target.value)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      label="Telefone"
                      id="fone-cadastro"
                      value={fone}
                      onChange={(e) => mudaFone(e)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      label="Profissao"
                      id="profissao-cadastro"
                      value={profissao}
                      onChange={(e) => setProfissao(e.target.value)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      label="Estado Civil"
                      id="civil-cadastro"
                      value={estadoCivil}
                      onChange={(e) => setEstadoCivil(e.target.value)}
                      fullWidth={true}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h5>Endereço</h5>
                    <TextField
                      label="Logradouro"
                      id="logradouro-cadastro"
                      value={endereco}
                      onChange={(e) => setEndereco(e.target.value)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      label="Número"
                      id="numero-endereco-cadastro"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      label="Complemento"
                      id="complemento-cadastro"
                      value={complemento}
                      onChange={(e) => setComplemento(e.target.value)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      label="Bairro"
                      id="bairro-cadastro"
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      label="CEP"
                      id="cep-cadastro"
                      value={cep}
                      onChange={(e) => mudaCEP(e)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      style={{ marginTop: 10 }}
                      options={estados}
                      getOptionLabel={(option) => option.NOME}
                      id="uf-cadastro"
                      onChange={(event, value) => estadoChange(value)}
                      defaultValue={estados[31]}
                      renderInput={(params) => (
                        <TextField {...params} label="Estado" />
                      )}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      style={{ marginTop: 10 }}
                      options={cidadesEstado}
                      getOptionLabel={(option) => option.NOME}
                      disabled={!selecCidade}
                      defaultValue={cidadesCompleto.data[31][0]}
                      value={cidadeSelecionada}
                      onChange={(event, value) => cidadeChange(value)}
                      id="cidade-cadastro"
                      renderInput={(params) => (
                        <TextField {...params} label="Cidade" />
                      )}
                      fullWidth={true}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <span>
                  <Button color="danger" onClick={() => setEtapa(1)}>
                    Cancelar
                  </Button>
                  <Button
                    color="success"
                    onClick={confereDados}
                    disabled={cadastraDisabled}
                  >
                    Cadastrar Cliente
                  </Button>
                </span>
              </CardFooter>
            </Card>
          ) : etapa == 3 ? (
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Alterar Dados do Cliente
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  Cliente: {cpfMask(clienteEdicao.CPF)}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <TextField
                      label="Nome"
                      id="nome-edicao"
                      name="NOME"
                      value={clienteEdicao.NOME}
                      onChange={clienteEdicaoChange}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      label="RG"
                      id="rg-edicao"
                      name="RG"
                      onChange={clienteEdicaoChange}
                      value={clienteEdicao.RG}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      label="Telefone"
                      id="fone-edicao"
                      name="FONE"
                      onChange={clienteEdicaoChange}
                      value={mascaraPhone(clienteEdicao.FONE)}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      label="Profissão"
                      id="profissao-edicao"
                      name="PROFISSAO"
                      onChange={clienteEdicaoChange}
                      value={clienteEdicao.PROFISSAO}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      label="Estado Civil"
                      id="civil-edicao"
                      name="ESTADO_CIVIL"
                      onChange={clienteEdicaoChange}
                      value={clienteEdicao.ESTADO_CIVIL}
                      fullWidth={true}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h5>Endereço</h5>
                    <TextField
                      label="Logradouro"
                      id="logradouro-edicao"
                      name="LOGRADOURO"
                      value={clienteEdicao.LOGRADOURO}
                      onChange={clienteEdicaoChange}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      label="Número"
                      id="numero-endereco-EDICAO"
                      name="NUMERO"
                      onChange={clienteEdicaoChange}
                      value={clienteEdicao.NUMERO}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      label="Complemento"
                      id="complemento-edicao"
                      name="COMPLEMENTO"
                      value={clienteEdicao.COMPLEMENTO}
                      onChange={clienteEdicaoChange}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      label="Bairro"
                      id="bairro-edicao"
                      name="BAIRRO"
                      value={clienteEdicao.BAIRRO}
                      onChange={clienteEdicaoChange}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      label="CEP"
                      id="cep-edicao"
                      name="CEP"
                      value={mascaraCEP(clienteEdicao.CEP)}
                      onChange={clienteEdicaoChange}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      style={{ marginTop: 10 }}
                      options={estados}
                      getOptionLabel={(option) => option.NOME}
                      id="uf-edicao"
                      onChange={(event, value) => estadoEdicaoChange(value)}
                      defaultValue={estados[31]}
                      value={estadoSelecionadoEdicao}
                      renderInput={(params) => (
                        <TextField {...params} label="Estado" />
                      )}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      style={{ marginTop: 10 }}
                      options={cidadesEstadoEdicao}
                      getOptionLabel={(option) => option.NOME}
                      defaultValue={
                        cidadesCompleto.data[clienteEdicao.COD_ESTADO][
                          clienteEdicao.COD_CIDADE
                        ]
                      }
                      value={cidadeSelecionadaEdicao}
                      onChange={(event, value) => cidadeChangeEdicao(value)}
                      id="cidade-edicao"
                      renderInput={(params) => (
                        <TextField {...params} label="Cidade" />
                      )}
                      fullWidth={true}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <span>
                  <Button
                    onClick={() => {
                      carregaClientes();
                      setEtapa(1);
                    }}
                    color="info"
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={() => {
                      confereEdicao();
                    }}
                    color="success"
                  >
                    Confirmar
                  </Button>
                </span>
              </CardFooter>
            </Card>
          ) : null}
          {etapa == 1 ? (
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Clientes</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Table
                      tableHeaderColor="primary"
                      tableHead={[
                        "CPF",
                        "Nome",
                        "Endereço",
                        "Cidade/UF",
                        "Editar",
                      ]}
                      tableData={clientes}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          ) : null}
        </GridItem>
        <Snackbar
          place="bc"
          color={corSnack || "warning"}
          icon={AddAlert}
          message={mensagemErro}
          open={alerta}
          closeNotification={() => setAlerta(false)}
          close
        />
      </GridContainer>
    </div>
  ) : (
    <Logout></Logout>
  );
}
